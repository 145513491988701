import { Flex, neutralDay, Text } from '@teamsparta/design-system';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Only from '@/components/atoms/Common/Only';
import { MobileGNBOpenAtom } from '@/states/landing';
import { Course } from '@/types';

import { MY_PAGE_GNB_BUTTONS } from '../../../constants/gnb/ongoing-gnb-button-list';
import * as S from './MobileMyPageGnb.style';

interface Props {
  courseName: Course;
}

export function MobileMyPageGnb({ courseName }: Props) {
  const router = useRouter();
  const mobileGnbOpen = useAtomValue(MobileGNBOpenAtom);

  if (mobileGnbOpen) return null;

  return (
    <Only.Mobile>
      <S.Wrapper>
        <Flex.Row
          gap={20}
          style={{
            width: 'max-content',
          }}
        >
          {MY_PAGE_GNB_BUTTONS[courseName].map(
            ({ name, url, icon, isNewTab }) => {
              const active = router.pathname.includes(url);

              return (
                <Link
                  href={url}
                  target={isNewTab ? '_blank' : '_self'}
                  rel={isNewTab ? 'noopener noreferrer' : ''}
                  key={name}
                  passHref
                  style={{
                    padding: '16px 0',
                    position: 'relative',
                  }}
                >
                  <Flex.Row align='center' gap={6}>
                    <Text
                      as='span'
                      font='mTitle2'
                      color={active ? neutralDay.white : neutralDay.gray60}
                    >
                      {name}
                    </Text>
                    {isNewTab && icon}
                  </Flex.Row>
                  {active && <S.Underline />}
                </Link>
              );
            },
          )}
        </Flex.Row>
      </S.Wrapper>
    </Only.Mobile>
  );
}
