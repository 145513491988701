import Footer from 'src/components/footer';
import LandingPageLnbOrganism from 'src/components/gnb';

import * as C from './components';
import * as S from './style';

interface HubPageTemplateProps {
  onlineUserId?: string;
}
export default function HubPageTemplate({
  onlineUserId,
}: HubPageTemplateProps) {
  return (
    <S.Container>
      <LandingPageLnbOrganism ssrOnlineUserId={onlineUserId} />
      <C.HubHero />
      <C.HubDifference />
      <C.HubReview />
      <C.HubStory />
      <C.HubCourses />
      <Footer />
    </S.Container>
  );
}
