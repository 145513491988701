import { instance } from '@/queries/api/axios';

import type {
  CarouselRequestParams,
  CarouselResponse,
  CarouselTypes,
} from './carousel.dto';

export async function getAllCarousels<L extends keyof CarouselTypes>(
  params: CarouselRequestParams<L>,
) {
  const { data } = await instance.get<CarouselResponse<L>>('/v3/carousels', {
    params,
  });

  return data;
}
