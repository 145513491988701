import { useRouter } from 'next/router';

import { useScrollDirection } from '@/businessLogics/_common/useScrollDirection';
import { useWindowScrollY } from '@/businessLogics/util-browser';
import Gnb from '@/components/molecules/GNB';

import * as S from './LandingPageLnbOrganism.style';

export default function LandingPageLnbOrganism({
  ssrOnlineUserId,
  fixed = false,
}: {
  ssrOnlineUserId?: string;
  fixed?: boolean;
}) {
  const router = useRouter();
  const scrollY = useWindowScrollY();
  const direction = useScrollDirection();

  const isSubPage =
    router.pathname.includes('faq') ||
    router.pathname.includes('curriculumn') ||
    router.pathname.includes('story') ||
    router.pathname.includes('exhibition');

  return (
    <>
      <S.LnbContents
        fixed={fixed}
        scrollY={scrollY}
        scrollDirection={direction}
        isSubpage={isSubPage}
      >
        <Gnb
          fixed={fixed}
          scrollY={scrollY}
          ssrOnlineUserId={ssrOnlineUserId}
        />
      </S.LnbContents>
    </>
  );
}
