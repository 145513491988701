import {
  ArrowChevronRightSmall,
  neutralDay,
  Text,
} from '@teamsparta/design-system';
import { Flex } from '@teamsparta/design-system';
import { Swiper, SwiperSlide } from 'swiper/react';

import CustomLink from '@/components/atoms/Common/CustomLink';
import { LoggingClick } from '@/components/atoms/Common/LoggingClick';
import { PostCard } from '@/pages/hub/components/Story/PostCard';

import { editorPost } from './data';
import * as S from './style';

export default function HubStoryTemplate() {
  return (
    <S.Wrapper>
      <S.Container>
        <Flex justify='between' align='center'>
          <Text
            as='h2'
            mFont='mHeader1'
            wFont='wHeader2'
            color='neutralDay.white'
          >
            에디터가 전하는 항해 이야기
          </Text>
          <S.DesktopOnly>
            <CustomLink href='https://hanghae99.spartacodingclub.kr/blog'>
              <Flex as='button' align='center'>
                <Text as='span' mFont='wBody3' color='neutralDay.gray60'>
                  더 보기
                </Text>
                <ArrowChevronRightSmall size={16} color={neutralDay.gray60} />
              </Flex>
            </CustomLink>
          </S.DesktopOnly>
        </Flex>
        <S.PostWrapper>
          <Swiper
            slidesPerView='auto'
            spaceBetween={20}
            style={{ width: '100%' }}
          >
            {editorPost?.map(({ title, description, link, imageUrl }) => (
              <SwiperSlide key={title}>
                <LoggingClick
                  logName='hh_hub_StoryCard_click'
                  data={{ card_title: title }}
                >
                  <CustomLink href={link}>
                    <PostCard
                      title={title}
                      description={description}
                      link={link}
                      imageUrl={imageUrl}
                    />
                  </CustomLink>
                </LoggingClick>
              </SwiperSlide>
            ))}
          </Swiper>
        </S.PostWrapper>
        <S.MobileOnly>
          <CustomLink href='https://hanghae99.spartacodingclub.kr/blog'>
            <S.MoreButton>
              <Text as='span' mFont='mBody1' color='neutralDay.white'>
                스토리 더 보기
              </Text>
              <ArrowChevronRightSmall size={16} color={neutralDay.white} />
            </S.MoreButton>
          </CustomLink>
        </S.MobileOnly>
      </S.Container>
    </S.Wrapper>
  );
}
