import { useQuery } from 'react-query';

import {
  type CarouselTypes,
  getAllCarousels,
} from '@/services/common/carousel';

const carouselQueryKeys = {
  all: ['carousel'],
  carouselByLocation: (location: keyof CarouselTypes) => [
    ...carouselQueryKeys.all,
    location,
  ],
};

export function useGetRemoteCarousel<L extends keyof CarouselTypes>({
  location,
}: {
  location: L;
}) {
  return useQuery(
    carouselQueryKeys.carouselByLocation(location),
    () => getAllCarousels({ location }),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      select: (data) => data.filter((carousel) => carousel.show),
    },
  );
}
