import styled from '@emotion/styled';
import { hanghae, neutralDay } from '@teamsparta/design-system';

export const Wrapper = styled.div`
  padding: 0px 16px;
  border-bottom: 1px solid ${neutralDay.gray90};
  overflow-x: scroll;
  background-color: ${neutralDay.gray100};

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Underline = styled.div`
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  background-color: ${hanghae.red100};
`;
